import React from "react"
import Container from "../layout/Container"
import WideContainer from "../layout/WideContainer"

export default function ParkSectionText() {
  const cardMarginLeft =
    "bg-white ml-2 md:ml-20 mr-2 md:mr-4 mt-2 md:mt-6 rounded"
  const cardMarginRight =
    "bg-white mr-2 md:mr-20 ml-2 md:ml-4 mt-2 md:mt-6 rounded"
  const cardPadding = "p-8"
  return (
    <div className="px-4 pb-0 md:pt-4 pt-0">
      <div className="bg-lightest py-10">
        <Container center={true}>
          <div className="text-center">
            <h2>Information at your hands</h2>
            <Container center={true}>
              <p className="text-lg">
                Planning a vacation can be time consuming. This is especially
                true for outdoor trips as they require more planning. Alper
                provies the info you need at your fingertips.
              </p>
            </Container>
          </div>
        </Container>
        <WideContainer center={false}>
          <div className="grid grid-cols-1 md:grid-cols-2 py-4">
            <div className={cardMarginLeft}>
              <div className={cardPadding}>
                <h4 className="mt-2">Hiking trails</h4>
                <p className="text-base">
                  Alper shows you some of the beautiful hiking trails in the
                  national parks. Our focus is always on quality over quantity.
                  This allows you to focus on a few amazing hikes rather than
                  uninteresting trails.
                </p>
              </div>
            </div>
            <div className={cardMarginRight}>
              <div className={cardPadding}>
                <h4 className="mt-2">Detailed maps</h4>
                <p className="text-base">
                  Plenty of websites are limited to texts describing the details
                  of the national park. Alper provides you with detailed maps
                  focused on including the exact information you need for your
                  visit.
                </p>
              </div>
            </div>
            <div className={cardMarginLeft}>
              <div className={cardPadding}>
                <h4 className="mt-2">Camping and cabins</h4>
                <p className="text-base">
                  Staying at the right place can make your trip alot better.
                  Alper shows you some of the cool places to stay in the
                  national park. This includes campsites, mountain cabins and
                  hotels.
                </p>
              </div>
            </div>
            <div className={cardMarginRight}>
              <div className={cardPadding}>
                <h4 className="mt-2">Parking and transport</h4>
                <p className="text-base">
                  Not all national parks have great information on where to park
                  your car. Alper provides locations of great places to park
                  when visiting. You will also be able to find information on
                  visiting with public transport.
                </p>
              </div>
            </div>
            <div className={cardMarginLeft}>
              <div className={cardPadding}>
                <h4 className="mt-2">Activities</h4>
                <p className="text-base">
                  A visit to a national park is not complete without exploring
                  the activities available. The specific activities are
                  different for every park. Alper shows you some of the
                  interesting activities available for you and your family.
                </p>
              </div>
            </div>
            <div className={cardMarginRight}>
              <div className={cardPadding}>
                <h4 className="mt-2">Facts</h4>
                <p className="text-base">
                  Sometimes you want to learn more about a national park. Alper
                  gives you cool facts about the park fauna, wildlife, history,
                  geology and more. Solid knwoledge of the park facts can make
                  your trip even better.
                </p>
              </div>
            </div>
          </div>
        </WideContainer>
      </div>
    </div>
  )
}
