import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { Edge } from '../../models/NationalPark';
import CustomMarker from "./CustomMarker";

const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_API_KEY;

interface Props {
    parks: Edge[]   
    zoom?: number;
}

export default function MapboxMultipleMarkers({ 
    parks,
    zoom = 4
}: Props) {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: 5
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/mapbox/outdoors-v11",
      center: [parks[0].node.data.national_park_coordinates.longitude, parks[0].node.data.national_park_coordinates.latitude],
      zoom: zoom,
    })
    map.addControl(new mapboxgl.NavigationControl(), "top-right");
    map.addControl(new mapboxgl.FullscreenControl(), "top-left");
    map.scrollZoom.disable();
    
    setMap(map);

    return () => map.remove()
  }, [])

  return (
    <div ref={mapContainerRef} style={mapContainerStyle}>
      {parks.map((item, index) => {
        return(
          <div key={index.toString()}>
            {map && item.node.data.national_park_coordinates && (
              <CustomMarker
                  map={map} 
                  coordinates={item.node.data.national_park_coordinates} 
              />
            )}
          </div>
        );
      })}
      
    </div>
  );
}